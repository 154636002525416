import { useState, useEffect, useMemo, Fragment } from "react";

const Timer = () => {
    const DEADLINE = "April 25, 2025";

    const SECOND = 1000;
    const MINUTE = SECOND * 60;
    const HOUR = MINUTE * 60;
    const DAY = HOUR * 24;

    const parsedDeadline = useMemo(() => Date.parse(DEADLINE), [DEADLINE]);
    const [time, setTime] = useState(parsedDeadline - Date.now());

    useEffect(() => {
        const interval = setInterval(
            () =>
                setTime(
                    parsedDeadline - Date.now() > 0
                        ? parsedDeadline - Date.now()
                        : 0
                ),
            1000
        );

        return () => clearInterval(interval);
    }, [parsedDeadline]);

    return (
        <div className="md:mt-20 rounded-xl mt-2">
            <div className="sm:px-12 gap-y-6 gap-x-2 md:gap-4 md:flex md:items-center md:justify-between md:mt-2 rounded-xl md:px-6 md:py-8 grid grid-cols-2 py-6">
                {Object.entries({
                    Days: Math.floor(time / DAY),
                    Hours: Math.floor((time / HOUR) % 24),
                    Minutes: Math.floor((time / MINUTE) % 60),
                    Seconds: Math.floor((time / SECOND) % 60),
                }).map(([label, value]) => (
                    <Fragment key={label}>
                        <div className="md:px-4 flex flex-col items-center px-10 mt-2">
                            <div className="md:w-28 md:h-28 lg:w-32 lg:h-32 md:text-4xl relative w-16 h-16 mt-4 text-2xl bg-transparent rounded-lg">
                                <div className="bg-glow md:text-7xl z-3 absolute flex flex-col items-center justify-center w-full h-full font-mono text-2xl font-bold text-black rounded-t-lg rounded-b-lg">
                                    <p className="tabular-nums text-center text-white">
                                        {value}
                                    </p>
                                </div>
                            </div>
                            <p className="sm:text-lg md:text-2xl mt-3 text-base font-semibold text-white">
                                {label}
                            </p>
                        </div>
                        {label !== "Seconds" && (
                            <span className="md:inline-block md:text-7xl text-gray-50 hidden -mt-8 text-5xl font-normal">
                                :
                            </span>
                        )}
                    </Fragment>
                ))}
            </div>
        </div>
    );
};
export default Timer;