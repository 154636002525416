import Title from "../Title/Title";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";

const Highlights = () => {
  return (
    <section className="highlights">
      <div className=" flex flex-col items-center">
        <Title text="SOFTEC 2025 Highlights" />

        <LiteYouTubeEmbed
          id="zgZ93mCd6Lk"
          poster="maxresdefault"
          webp
          aspectHeight={7}
          params="rel=0"
        />
      </div>
    </section>
  );
};

export default Highlights;
