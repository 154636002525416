import { imagesArr } from "../../assets";
import Spacer from "../Spacer/Spacer";
const Footer = () => {
    return (
        <>
            <Spacer />
            <footer
                className="bg-logo-indigo mt-16"
                aria-labelledby="footer-heading"
            >
                <h2 id="footer-heading" className="sr-only">
                    Footer
                </h2>
                <section className="max-w-7xl sm:px-6 lg:py-16 lg:px-8 px-4 py-12 mx-auto">
                    <div className="md:grid md:grid-cols-3 md:gap-8">
                        <div className="md:grid md:grid-cols-2 md:gap-8 md:col-span-2">
                            <div className="xl:mt-0 mt-8">
                                <div>
                                    <img
                                        src={imagesArr.LogoRed}
                                        alt="SOFTEC Logo"
                                        width={200}
                                        height={200}
                                        title="SOFTEC Logo"
                                        loading="lazy"
                                        className="md:w-[150px] mx-auto md:m-0 w-[100px] object-cover mb-2 p-2 "
                                    />
                                    <p className="md:text-3xl md:text-left text-2xl font-semibold text-center text-white uppercase">
                                        Contact US
                                    </p>
                                    <p className="md:text-left mt-2 text-lg text-center text-white">
                                        In case of any query contact us at:{" "}
                                        <a
                                            href="mailto:info@softec.org.pk"
                                            className="md:text-left mt-4 text-lg font-bold text-center text-white"
                                        >
                                            info@softec.org.pk
                                        </a>
                                    </p>
                                    <p className="md:text-left mt-4 text-lg font-bold text-center text-white">
                                        852 B Faisal Town, Lahore, Pakistan
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="xl:mt-0 flex flex-col justify-end mt-8">
                            <h3 className="md:text-3xl md:text-left text-2xl font-semibold tracking-wider text-center text-white uppercase">
                                Be our Sponsor
                            </h3>
                            <p className="md:text-left mt-4 text-lg text-center text-white">
                                If you're interested in becoming a sponsor,
                                please send us an email by clicking the button below. Our team will contact you soon!
                            </p>
                            <form className="sm:flex sm:max-w-md mt-4">
                                <div className="sm:mt-0 sm:flex-shrink-0 w-full pt-4 mt-3 rounded-md">
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="mailto:marketing@softec.org.pk"
                                    >
                                        <button
                                            type="button"
                                            className="w-full h-16 bg-white rounded-md py-2 px-4 flex items-center justify-center text-xl font-bold text-logo-indigo hover:bg-logo-indigo hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 shadow-2xl border-white border-[3px]"
                                        >
                                            Become Our Sponsor
                                        </button>
                                    </a>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="md:mt-8 md:pt-8 md:flex md:items-center md:justify-between mt-8 border-t border-white">
                        <p className="md:mt-0 md:order-1 mt-8 text-base text-center text-white">
                            © {new Date().getFullYear()} SOFTEC. All rights
                            reserved.
                        </p>
                        <div className="md:order-2 md:mt-0 flex justify-center mt-6 space-x-6">
                            <a
                                href="https://www.facebook.com/SOFTEC.ORG"
                                className="hover:text-gray-300 text-white"
                            >
                                <span className="sr-only">Facebook</span>
                                <img
                                    src={imagesArr.FacebookOutlined}
                                    alt="Facebook"
                                    width={20}
                                    height={20}
                                />
                            </a>
                            <a
                                href="https://www.instagram.com/softec_nu/"
                                className="hover:text-gray-300 text-white"
                            >
                                <span className="sr-only">Instagram</span>
                                <img
                                    src={imagesArr.Instagram}
                                    alt="Instagram"
                                    width={20}
                                    height={20}
                                />
                            </a>
                            <a
                                href="https://www.linkedin.com/company/softecnu"
                                className="hover:text-gray-300 text-white"
                            >
                                <span className="sr-only">LinkedIn</span>
                                <img
                                    src={imagesArr.LinkedIn}
                                    alt="LinkedIn"
                                    width={20}
                                    height={20}
                                />
                            </a>
                            <a
                                href="https://www.youtube.com/@softecnu"
                                className="hover:text-gray-300 text-white"
                            >
                                <span className="sr-only">Youtube</span>
                                <img
                                    src={imagesArr.Youtube}
                                    alt="Youtube"
                                    width={20}
                                    height={20}
                                />
                            </a>
                        </div>
                    </div>
                </section>
            </footer>
        </>
    );
};

export default Footer;
